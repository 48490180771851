import * as React from 'react';
import Map, {Marker, GeolocateControl} from 'react-map-gl/maplibre';

import badgeshine from "../img/badgeshine.png";
import closeicon from "../img/close-white.svg";

function ModalMap(props) {

  let badgepos = JSON.parse(props.trails[0].mapbadgepos)

  return (
    <div className={`modalmap ${props.open ? null : "modalmapclosed"}`} >

      <div className='closebutton' onClick={props.onClose}><img src={closeicon} /></div>


        <Map
          reuseMaps
          initialViewState={{
            longitude: badgepos.long,
            latitude: badgepos.lat,
            zoom: 12
          }}
          style={{width: '100vw', height: '100vh'}}
          mapStyle="https://api.maptiler.com/maps/streets/style.json?key=QYcfLYFnmUmAzAt5Cv7Z	"
      >
        {
          props.trails.map((obj, index) => {
            let pos = JSON.parse(obj.mapbadgepos);
            return (<Marker key={index} onClick={() => props.onmarkerclick(index)} longitude={pos.long} latitude={pos.lat} anchor="center" >
              <div style={{backgroundImage: `url(${obj.badge})`}}  className='badge-detail badge-map'><img src={badgeshine} className="badgeshine" /></div>
            </Marker>)
          })
        }
        {/*<GeolocateControl />*/}
      </Map>
    </div>
  );
}

export default ModalMap;
