import * as React from 'react';

import badgeshine from "../img/badgeshine.png";
import closeicon from "../img/close-white.svg";
import Badge from './Badge';

import badgeempty from "../img/badgeempty.svg";

function ModalBadges(props) {

  return (
    <div className={`modalbadge ${props.open ? null : "modalbadgeclosed"}`} >

      <div className='closebutton' onClick={props.onClose}><img src={closeicon} /></div>

      <div className='wrapper badgecollectionsection' style={{paddingTop: 24, paddingBottom: 40}}>
        <h2 className='badgecollectionheadline'>{props.headline}</h2>
        <div className='badgecollectionwrapper'>
          {/*
            props.trails.map((obj, index) => (
              <div className='badgecollectionitem'>
                <Badge img={obj.badge} active={obj.done}/>
                <p className='badgecollectionitemtitle'>{obj.title}</p>
                {obj.done ? <p className='badgecollectioniteminfotext'>Geschafft am {obj?.finishdate}</p> : null}
              </div>
            ))
            */}
            <div className='badgecollectionitem'>
                <Badge img={props.trails[0].badge} active={true}/>
                <p className='badgecollectionitemtitle'>{props.trails[0].title}</p>
                <p className='badgecollectioniteminfotext'>Geschafft am 12.07.2023</p>
            </div>
            <div className='badgecollectionitem'>
                <img className='badgecollectionitemempty' src={badgeempty} />
            </div>
            <div className='badgecollectionitem'>
                <img className='badgecollectionitemempty' src={badgeempty} />
            </div>
            <div className='badgecollectionitem'>
                <img className='badgecollectionitemempty' src={badgeempty} />
            </div>
            <div className='badgecollectionitem'>
                <img className='badgecollectionitemempty' src={badgeempty} />
            </div>
            <div className='badgecollectionitem'>
                <img className='badgecollectionitemempty' src={badgeempty} />
            </div>
            


        </div>
      </div>
        
    </div>
  );
}

export default ModalBadges;
