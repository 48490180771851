// UPDATE 18. September: kompakter, ohne headline?, neue Badgesektion

import { useState, useRef, useEffect } from 'react';
import './App.css';
import TrailCard from './components/Trailcard';
import Badgecounter from './components/Badgecounter';
import Badge from './components/Badge';
import ModalMap from './components/ModalMap';
import ModalBadges from './components/ModalBadges';


import badgeshine from "./img/badgeshine.png";

import TrailDetail from './TrailDetail-2';

//import tl from './translations';
import { translate } from './functions/translationHelper';

function Overview() {
/*
  const mockdata = {
    id: "2",
    slug: "leitlhof",
    language: "de",
    headline: "Die Natur der Dolomiten",
    customcss: "@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&family= Inclusive+Sans&display=swap');.trailcard h3{font-size:32px;}:root {--main-color: #A39D91;--dark-color:#716856; --font-body: 'Inclusive Sans', sans-serif;\n  --font-display: 'Nanum Myeongjo', serif;--font-display-weight: normal}.badgetopsection{background:#726B5D}.trailtitlebox h2 {\n    font-size: 32px;\n}",
    trails: [
      {
        id: "3",
        stationcount: "1",
        time: "210",
        distance: "8600",
        elevation: "330",
        route: "",
        title: "Strudelkopf",
        subtitle: "Einfache idyllische Wanderung",
        description: "Eine einfache Wanderung für die ganze Familie oder ein lohnendes Ziel für Bike&Hike: Vom Berggasthaus Plätzwiese über die Dürrensteinhütte wandert man durch weites Gelänge und folgt kleinen Steigen zum Gipfel. Alternativ geht es mit dem Bike bis zur Dürrensteinhütte, dort tauscht man Radhelm gegen Waderstöcke.",
        logoscale: "80",
        options: "{\"uselogo\": false}",
        mapbadgepos: "{\"lat\": 46.766546, \"long\": 12.317264}",
        startaddress: "Via Kranzhof 7, 39038 San Candido BZ, Italy",
        badge: "https://content.wunderweg.net/leitlhof/3/badge.png",
        logo: "https://content.wunderweg.net/leitlhof/3/logo-de.svg",
        logocard: "https://content.wunderweg.net/leitlhof/3/logocard-de.svg",
        image: "https://content.wunderweg.net/leitlhof/3/card.png"
      },
      {
        id: "6",
        stationcount: "1",
        time: "540",
        distance: "14100",
        elevation: "1015",
        route: "",
        title: "Morgenkopf Umrundung",
        subtitle: "Ganztagesausflug auf über 1000 Höhenmeter",
        description: "Diese Höhenwanderung ist ein Muss für passionierte Bergsteiger, die neben ausreichend Kondition ein Stück alpiner Erfahrung mitbringen. Unser Weg führt von Antoniusstein hinauf durchs wunderschöne Innerfeldtal. Besonders die Lärchenwiesen und die artenreiche Pflanzenwelt begeistert Naturliebhaber immer wieder aufs Neue. Dann geht es hinauf zum Wildgrabenjoch und in einem Bogen wieder zurück durch das Innerfeldtal.",
        logoscale: "80",
        options: "{\"uselogo\": false}",
        mapbadgepos: "{\"lat\": 46.744814, \"long\": 12.260231}",
        startaddress: "Gasthof Waldruhe, 39030 Sexten, Bozen",
        badge: "https://content.wunderweg.net/leitlhof/6/badge.png",
        logo: "https://content.wunderweg.net/leitlhof/6/logo-de.svg",
        logocard: "https://content.wunderweg.net/leitlhof/6/logocard-de.svg",
        image: "https://content.wunderweg.net/leitlhof/6/card.png"
      },
      {
        id: "7",
        stationcount: "1",
        time: "270",
        distance: "6600",
        elevation: "680",
        route: "",
        title: "Gantraste",
        subtitle: "Wanderung auf 2130 m",
        description: "Mit der Sesselbahn Haunold geht es von Innichen hinauf zum Ausgangspunkt bei der Haunoldhütte. Von hier rechts halten und auf Steig Nr. 6 zum Maier Kaser und der Markierung links folgend hoch zur Gantraste (2130 m). Ausblick über Innichen und das Pustertal.\r\nAbstieg über den beschriebenen Aufstiegsweg.",
        logoscale: "75",
        options: "{\"uselogo\": false}",
        mapbadgepos: "{\"lat\": 46.713186, \"long\": 12.19632}",
        startaddress: "Baumdorf, 39034 Toblach, Bozen",
        badge: "https://content.wunderweg.net/leitlhof/7/badge.png",
        logo: "https://content.wunderweg.net/leitlhof/7/logo-de.svg",
        logocard: "https://content.wunderweg.net/leitlhof/7/logocard-de.svg",
        image: "https://content.wunderweg.net/leitlhof/7/card.png"
      },
      {
        id: "8",
        stationcount: "1",
        time: "210",
        distance: "11400",
        elevation: "374",
        route: "",
        title: "Dreischusterhütte",
        subtitle: "Entdecke die Sextner Dolomiten",
        description: "Wohl eine der schönsten Wanderungen, die Innichen zu bieten hat. Sie punktet vor allem mit den wundervollen Bergen der Sextner Dolomiten,\r\ndie sich über das ganze Innerfeldtal hinziehen. Außerdem liegt das Gebiet im Naturpark Drei Zinnen und ist deshalb ein Ort besonderer Ruhe und Schönheit.\r\nZiel dieser Wanderung ist die Drei-Schuster-Hütte, die von Juni bis Anfang Oktober geöffnet ist und auch Übernachtungsmöglichkeiten bietet.\r\nDie Wanderung ist relativ leicht und beinhaltet keine sonderlich schwierigen Passagen.",
        logoscale: "90",
        options: "{\"uselogo\": false}",
        mapbadgepos: "{\"lat\": 46.688726, \"long\": 12.312592}",
        startaddress: "",
        badge: "https://content.wunderweg.net/leitlhof/8/badge.png",
        logo: "https://content.wunderweg.net/leitlhof/8/logo-de.svg",
        logocard: "https://content.wunderweg.net/leitlhof/8/logocard-de.svg",
        image: "https://content.wunderweg.net/leitlhof/8/card.png"
      }
    ]
  };
*/

  const mockdata2 = {
    id: "3",
    slug: "oetztal",
    language: "de",
    headline: "Entdecke Ötztal",
    customcss: "@font-face {font-family: 'Adelle Oetztal';src:url('fonts/AdelleOetztal-Regular.otf') format('opentype'), font-weight: 'normal';}@font-face {font-family: 'Adelle Oetztal Bold';src:url('fonts/AdelleOetztal-Bold.otf') format('opentype'), font-weight: 'bold';}.trailcard h3{font-size:32px;}:root {--main-color: #A12437;--font-body: 'Adelle Oetztal', sans-serif;\n  --font-display: 'Adelle Oetztal Bold', sans-serif;--font-display-weight: 'normal';--dark-color: #361708;}.badgetopsection{background:#380A08}.trailtitlebox h2 {\n    font-size: 28px;\n}.button-primary{font-size: 20px;font-family: 'Adelle Oetztal Bold';font-weight: normal}.trailtitlebox p {font-size: 16px;}.trailinfocontainer p span {font-family:'Adelle Oetztal Bold'; font-weight: normal}",
    trails: [
      {
        id: "1",
        stationcount: "8",
        time: "120",
        distance: "4800",
        elevation: "430",
        route: "",
        title: "Entdecke den Stuibenfall",
        subtitle: "Für sportlich aktive Entdecker",
        description: "Dieser einzigartige Pfad führt dich zu einem der spektakulärsten Naturwunder - dem Stuibenfall. Aber das ist noch nicht alles! Unterwegs erwarten dich informative Videos, die das Geheimnis dieses beeindruckenden Ortes lüften, sowie Minispiele, die dein Wissen herausfordern. Tauche ein in die Schönheit der Natur und genieße gleichzeitig eine interaktive Lernerfahrung. Ein Abenteuer für die Sinne und den Geist gleichermaßen. Komm und entdecke den Stuibenfall auf eine völlig neue Art und Weise!",
        logoscale: "80",
        options: "{\"uselogo\": true}",
        mapbadgepos: "{\"lat\": 47.131879, \"long\": 10.930795}",
        startaddress: "Parkplatz Bischofsplatz,Am Tauferberg 1,6441 Umhausen",
        badge: require("./img/oetztal/badge1-2.png"),
        logo: require("./img/oetztal/logo1.svg").default,
        logocard: require("./img/oetztal/logo1.svg").default,
        image: require("./img/oetztal/card1-2.png")
      },
      {
        id: "2",
        stationcount: "8",
        time: "90",
        distance: "4200",
        elevation: "120",
        route: "",
        title: "Niederthai Challenge",
        subtitle: "Knacke alle Rätsel und rette das Dorf",
        description: "Die Niederthai Challenge - Dein ultimatives Outdoor-Abenteuer! Bereit für Nervenkitzel und Rätselspaß in der Natur? Knacke Geheimnisse, entdecke Schätze und erlebe Abenteuer pur. Schaffst du es alle Rätsel zu knacken?",
        logoscale: "80",
        options: "{\"uselogo\": true}",
        mapbadgepos: "{\"lat\": 47.128438, \"long\": 10.968524}",
        startaddress: "Parkplatz Niederthai,Niederthai 1,6441 Umhausen",
        badge: require("./img/oetztal/badge2.png"),
        logo: require("./img/oetztal/logo2.svg").default,
        logocard: require("./img/oetztal/logo2.svg").default,
        image: require("./img/oetztal/card2.png")
      },
      {
        id: "3",
        stationcount: "8",
        time: "110",
        distance: "4800",
        elevation: "460",
        route: "",
        title: "Unter Wasser",
        subtitle: "Lerne die Hochwassergeschichte in Längenfeld",
        description: "Dieser einzigartige Weg führt dich in die Tiefen der Geschichte von Hochwasserereignissen in Längenfeld. Informative Videos nehmen dich mit auf eine Reise durch die Vergangenheit, um die Auswirkungen und Lösungen von Hochwasser zu verstehen. Erfahre, wie die Gemeinschaft mit diesen Herausforderungen umgegangen ist und wie sie sich schützt. Ein Bildungserlebnis, das deine Perspektive erweitert und dir ein tieferes Verständnis für die Umweltauswirkungen bietet.",
        logoscale: "65",
        options: "{\"uselogo\": true}",
        mapbadgepos: "{\"lat\": 47.068280, \"long\": 10.963058}",
        startaddress: "Aqua Dome,Oberlängenfeld 140,6444 Oberlängenfeld",
        badge: require("./img/oetztal/badge3.png"),
        logo: require("./img/oetztal/logo3.svg").default,
        logocard: require("./img/oetztal/logo3.svg").default,
        image: require("./img/oetztal/card3.png")
      }
      
    ]
  }

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(mockdata2);
  const [error, setError] = useState(false);

  const [badgeopen, setbadgeopen] = useState(false);
  const [mapopen, setmapopen] = useState(false);

  const [detailopen, setdetailopen] = useState(false);
  const [currenttrail, setcurrenttrail] = useState(0);

  const badgecontainer = useRef(null);

  function opencard(id) {
    setcurrenttrail(id);
    setdetailopen(true);
  }

  const [region, setRegion] = useState(0);
  const [lang, setLang] = useState(0);

  useEffect(() => {
    // Call the function to fetch data here
    setcurrenttrail(0);

  }, [region, lang]);




  


  

  return (

    <div className="page-overview">
      

          <div className='wrapper overviewwrapper' style={{ paddingBottom: 24 }}>
            {/*<Badgecounter/>
        <div className='badgecounterlabel'>
          <b style={{marginRight: 8}}>1/{cards.length}</b> Mein Sammelpass
          <img src={require("./img/arrow.png")} />
  </div>*/}


            <div className='overviewlistbar'>
              <div className='mapbutton-secondary' onClick={() => setmapopen(true)}>{translate("map", data.language)}</div>
              <div className='badgecountercont' onClick={() => setbadgeopen(true)}>
                <div style={{letterSpacing: 2}}><b>1</b>/{data?.trails.length}</div>
                <div style={{ backgroundImage: `url(${data?.trails[0].badge})` }} className='badge-detail-home'><img src={badgeshine} className="badgeshine" /></div>
              </div>
            </div>

            <div>
              <h1 style={{fontFamily: 'Adelle Oetztal', width: 300, fontSize: 56}}>{data.headline}</h1>
            </div>

            {
              data?.trails.map((obj, index) => <div key={index} onClick={() => opencard(index)}><TrailCard lang={data.language} title={obj.title} img={obj.image} logo={obj.logo} cardlogo={obj.logocard} logoscale={obj?.logoscale} done={obj.done} stations={obj.stationcount} intensity={obj.difficulty} time={obj.time} distance={obj.distance} options={JSON.parse(obj?.options)} /></div>)
            }
          </div>

          

          {/*<div className='mapbutton'  onClick={() => setmapopen(!mapopen)}>{mapopen ? translate("list", data.language) : translate("map", data.language)}</div>*/}
          <ModalMap open={mapopen} trails={data?.trails} onmarkerclick={(x) => opencard(x)} onClose={() => setmapopen(false)} />
          <ModalBadges open={badgeopen} onClose={() => setbadgeopen(false)} headline={translate("stickers", data.language)} trails={data.trails} />

          <TrailDetail lang={data?.language} open={detailopen} onClose={() => setdetailopen(false)} infos={data?.trails[currenttrail]} />
          <style>{data.customcss}</style>
          {detailopen || badgeopen ? <style>{"body{overflow:hidden;}"}</style> : null}
      
    </div>

  );
}

export default Overview;
