const tl = {
      stickers: {
        de: "Mein Sammelpass",
        en: "My Stickers",
        it: "I miei adesivi"
      },
      trails: {
        de: "Wege",
        en: "Trails",
        it: "Sentieri"
      },
      trail_singular: {
        de: "Weg",
        en: "Trail",
        it: "Sentiero"
      },
      stations: {
        de: "Stationen",
        en: "stations",
        it: "Stazioni"
      },
      station_singular: {
        de: "Station",
        en: "station",
        it: "Stazione"
      },
      hours: {
        de: "Stunden",
        en: "hours",
        it: "ore"
      },
      hour_singular: {
        de: "Stunde",
        en: "hour",
        it: "ora"
      },
      difficulty: {
        de: ["Leicht", "Mittel", "Schwer"],
        en: ["Easy", "Medium", "Hard"],
        it: ["Facile", "Medio", "Difficile"]
      },
      map: {
        de: "Karte",
        en: "Map",
        it: "Mappa"
      },
      list: {
        de: "Liste",
        en: "List",
        it: "Lista"
      },
      startnow: {
        de: "Jetzt starten",
        en: "Start now",
        it: "Inizia ora"
      },
      arrival: {
        de: "Startpunkt & Anreise",
        en: "Starting point & arrival",
        it: "Punto di partenza e arrivo"
      },
      route: {
        de: "Route anzeigen",
        en: "Show route",
        it: "Mostra percorso"
      },
      reload: {
        de: "Neu laden",
        en: "Reload"
      },
      poi_water: {
        de: "Trinkwasser",
        en: "Drinking water",
        it: "Acqua potabile"
      },
      poi_restaurant: {
        de: "Restaurant",
        en: "Restaurant",
        it: "Ristorante"
      },
      poi_toilet: {
        de: "Toilette",
        en: "Toilet",
        it: "Servizi igienici"
      },
      poi_photopoint: {
        de: "Fotopoint",
        en: "Photopoint",
        it: "Punto foto"
      },
      poi_pin: {
        de: "Markierung",
        en: "Marking",
        it: "Marcatura"
      },
      opennow: {
        de: "Jetzt öffnen",
        en: "Open now",
        it: "Aperto ora"
      }
  };

export default tl;