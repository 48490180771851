import { useState, useEffect, useRef } from 'react';
import './App.css';
import './traildetail.css';

import badge1 from "./img/badge1.png";
import logo4 from "./img/logo4.svg";
import closeicon from './img/close-white.svg';

import iconstation from "./img/detail-station.svg";
import iconlength from "./img/detail-length.svg";
import icontime from "./img/detail-time.svg";
import iconintensity from "./img/detail-intensity.svg";

import markerbg from "./img/markerbg.png";

import pinplaceholder from "./img/pin-placeholder.png";

import playicon from "./img/play.svg";

import getIntensity from './functions/getIntensity';
import getIntensityIcon from './functions/getIntensityicon';

import badgeshine from "./img/badgeshine.png";

import formatTime from './functions/formatTime';
import formatDistance from './functions/formatDistance';

import Map, {Marker} from 'react-map-gl/maplibre';

import Trailer from './components/Trailer';

//import tl from './translations';
import { translate } from './functions/translationHelper';

function Accordeon() {
  const [active, setactive] = useState(false);

  return (
    <div style={{padding: 20}} onClick={() => setactive(!active)}>i am {active ? "open" : "closed"}</div>
  )
}

function TrailDetail(props) {

  const [traileropen, setTraileropen] = useState(false);

 
  const [data, setData] = useState([
    {
      stationid: "6",
      title: "Station 1",
      lat: "47.126783",
      long: "10.945227"
    }
  ]);

  const divRef = useRef(null);

  let stations = [
    {
      title: "Das Zirporchester",
      type: "Spiel",
      icon: require("./img/badge1.png")
    },
    {
      title: "Hungriger Auerhahn",
      type: "Video",
      icon: require("./img/badge2.png")
    },
    {
      title: "Schmetterlingschaos",
      type: "Erzählung",
      icon: require("./img/badge3.png")
    },
    {
      title: "Die alte Hexen",
      type: "Spiel",
      icon: require("./img/badge1.png")
    },
    {
      title: "Das Wasserrad",
      type: "Video",
      icon: require("./img/badge2.png")
    }
  ]

  useEffect(() => {
    if(props.open) {
     
      divRef.current.scrollTo({
        top: 0,
      });
    }
  }, [props.open])



  function formatAddressString(address) {
    const addressParts = address.split(',').map(part => part.trim());
    const formattedAddress = addressParts.join('\n');
    return formattedAddress;

    console.log(formattedAddress);
  }


  return (
    <div className={`page-traildetail ${props.open ? null : "page-traildetailclosed"}`}>

      <div className='closebutton' onClick={props.onClose}><img src={closeicon} /></div>

      <div className='traildetailscrollcontainer' ref={divRef}>

      <div className='trailer' style={{backgroundImage: `url(${props?.infos?.image})`}} onClick={() => setTraileropen(true)}>
        <div className='trailerplayicon'><img src={playicon} /></div>
      </div>

      {/* Second sticky bottom button
      <div className='startbuttonbottombox'>
        <div className='wrapper'>
          <div className='button-primary'>Jetzt starten</div>
        </div>
      </div>
      */}
      
      <div className='wrapper' style={{marginTop: 32, marginBottom: 56}}>

        <div className='trailtitlebox'>
          <h2>{props.infos.title}</h2>
          <p>{props.infos.subtitle}</p>
        </div>


        <div className='button-primary' style={{marginTop: 28, marginBottom: 24}}>{translate("startnow", props.lang)}</div>

        <div className='trailinfocontainer'>
            <p><span>{formatDistance(props.infos.distance)}</span> km</p>
            <p><span>{props.infos.elevation}</span> hm</p>
            <p><span>{formatTime(props.infos.time)}</span> h</p>
        </div>


        <p className='detaildescription'>
          {props.infos.description}
        </p>

      </div>

      {/*<img src={require("./img/map-mock.png")} style={{width: '100%'}} />*/}
      


        
        <div>
        <Map
          reuseMaps
          initialViewState={{
            longitude: data[0].long,
            latitude: data[0].lat,
            zoom: 12
          }}
          style={{width: '100vw', height: 300}}
          mapStyle="https://api.maptiler.com/maps/streets/style.json?key=QYcfLYFnmUmAzAt5Cv7Z"
        >
          {
          data.map((obj, index) => {
            return (<Marker key={index} longitude={obj.long} latitude={obj.lat} anchor="bottom">
              <div className='stationmarker'>
                <img src={markerbg} className='markerbg' />
                <div style={{backgroundImage: `url(${pinplaceholder}`}}  className='markerimginside'>
                  <div style={{backgroundImage: `url(${obj.image})`}}  className='markerimginsideinner'></div>
                </div>
              </div>
            </Marker>)
          })
        }
        </Map>
        <div className='wrapper' style={{marginTop: 48, marginBottom: 56}}>
        <div className='detail-section-stationen'>
        <h2>{data.length} {data.length == 1 ? translate("station_singular", props.lang) : translate("stations", props.lang)}</h2>

        <div className='stationlistwrapper'>
          {
            data?.map((obj, index) => (
              <div className='stationlistitem' key={index}>
                <div style={{backgroundImage: `url(${pinplaceholder})`}}  className='stationlistitemimage'>
                  <div style={{backgroundImage: `url(${obj.image})`}}  className='stationlistitemimageinside'></div>
                </div>
                <div className='stationlistitemtext'>
                  <p style={{fontSize: 16, marginBottom: 2}}>{obj.title}</p>
                  {/*<p style={{fontSize: 13, opacity: 0.7}}>{obj.type}</p>*/}
                </div>
              </div>
            ))
          }
        </div>
        </div>


        <div className='detail-section-anfahrt' style={{marginTop: 40}}>
          <h2>{translate("arrival", props.lang)}</h2>
          <div className='detail-section-anfahrt-textcont'>{props?.infos?.startaddress.split(',').map((part, i) => <p key={i}>{part.trim()}</p>)}</div>

          {/*<div onClick={() => window.open(`https://maps.google.com/?q=${data[0].lat},${data[0].long}`, '_self')} className='button-secondary'>{translate("route", props.lang)}</div>*/}
          </div>
          </div>
        </div>
        

      </div>

      <div className={`pagewrapper-trailer ${!traileropen && "pagewrapper-trailer-closed"}`}><Trailer url={"https://content.wunderweg.net/oetztal/001.mp4"} active={traileropen} onClose={() => setTraileropen(false)} /></div>

      
    </div>

    
  );
}

export default TrailDetail;
